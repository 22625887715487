import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Footer = (props) => (
  <div className="footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="footer">
            <h3 className="footer-title text-center">
              ©
              {' '}
              {new Date().getFullYear()}
              {' '}
              {props.data.site.siteMetadata.fullName}.
              {' '}
              All Rights Reserved.
              {' '}
              Created by{' '}
              <a href="https://zest.studio" target="_blank" rel="noreferrer">Zest Studio</a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            fullName
          }
        }
      }
    `}
    render={(data) => <Footer data={data}/>}
  />
);
