import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ toggleMenu }) => {
  const [hamburgerActive, setHamburgerActive] = useState(false);

  const handleToggle = () => {
    setHamburgerActive(!hamburgerActive);
    toggleMenu(hamburgerActive);
  };

  return (
    <button
      id="toggle-main-menu-mobile"
      className={`hamburger hamburger--slider ${
        hamburgerActive ? 'is-active' : ''
      }`}
      type="button"
      onClick={handleToggle}
      aria-label="Menu"
    >
        <span className="hamburger-box">
          <span className="hamburger-inner"/>
        </span>
    </button>
  );
};

Hamburger.propTypes = {
  toggleMenu: PropTypes.func.isRequired
};

export default Hamburger;
