import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';

const MenuMobile = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;

  const otherLang = props.langs.find(lang => !lang.selected);

  const currentLang = props.langs.find(lang => lang.selected);

  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${props.active ? 'open' : ''}`}
    >
      <ul>
        {menuLinks.map((link) => {
          const { langKey } = currentLang;

          return (
            <li key={link[`${langKey}Name`]}>
              <Link to={`${langKey === 'en' ? '' : `/${langKey}`}${link.link}`}>{link[`${langKey}Name`]}</Link>
            </li>
          );
        })}
        <li>
          <Link to={otherLang.link} className="language">
            <FormattedMessage id="language"/>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              enName
              zhName
              link
            }
          }
        }
      }
    `}
    render={data => <MenuMobile active={props.active} data={data} {...props}/>}
  />
);
