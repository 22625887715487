import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { graphql, StaticQuery } from 'gatsby';
import favicon from '../../static/favicon.jpg';

const SEO = ({ title, lang }) => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const seoTitle = title || data.site.siteMetadata.title;
      return (
        <Helmet
          htmlAttributes={{
            lang: lang || 'en',
          }}
          title={seoTitle}
          titleTemplate={`%s - ${data.site.siteMetadata.title}`}
          link={[
            { rel: 'shortcut icon', type: 'image/jpg', href: `${favicon}` },
          ]}
        />
      );
    }}
  />
);

SEO.propTypes = {
  title: PropTypes.string,
  lang: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  meta: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  keywords: PropTypes.array,
};

SEO.defaultProps = {
  title: '',
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
