import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import 'intl';
import { IntlProvider } from 'react-intl';

import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../scss/style.scss';

const Layout = ({ children, bodyClass, location, i18nMessages }) => (
  <StaticQuery
    query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }      
            }
          }
          allFeaturesJson {
            edges {
              node {
                  id
                  title
                  description
                  image
            }
          }
        }
       }
    `}
    render={(data) => {
      const url = location.pathname || '/';
      const { langs, defaultLangKey } = data.site.siteMetadata.languages;
      const langKey = getCurrentLangKey(langs, defaultLangKey, url);
      const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
      const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => (
        {
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, '/'),
        }
      ));

      return (
        <IntlProvider
          locale={langKey}
          messages={i18nMessages}
          defaultLocale={defaultLangKey}
        >
          <SEO/>
          <div className={`page${bodyClass ? ` ${bodyClass}` : ''}`}>
            <div id="wrapper" className="wrapper">
              <Header langs={langsMenu}/>
              {children}
            </div>
            <Footer/>
          </div>
        </IntlProvider>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  i18nMessages: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

Layout.defaultProps = {
  bodyClass: null,
  i18nMessages: {},
  location: {},
};

export default Layout;
