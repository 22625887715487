import React, { useMemo, useState } from 'react';
import { Link } from 'gatsby';

import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../images/logo-mobile.jpg';
import MenuMobile from './MenuMobile';

const Header = (props) => {
  const [menuActive, setMenuActive] = useState(false);

  const { langKey } = props.langs.find(lang => lang.selected);

  const link = useMemo(() => (langKey === 'zh' ? '/zh' : '/'), [langKey]);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div className="header sticky-top">
      <div className="header-container">
        <div className="logo">
          <Link to={link}>
            <img alt="Massam Corporate Consulting Website" src={logo}/>
          </Link>
        </div>
        <div className="logo-mobile">
          <Link to={link}>
            <img alt="Massam Corporate Consulting Website" src={logo}/>
          </Link>
        </div>
        <MenuMobile active={menuActive} langs={props.langs}/>
        <Menu langs={props.langs}/>
        <Hamburger toggleMenu={toggleMenu}/>
      </div>
    </div>
  );
};

export default Header;
